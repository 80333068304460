.fraction {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 0.2em;
  vertical-align: middle;
  height: 2em;  /* Match line-height of question */
  justify-content: center;
}

.fraction.inline {
  font-size: 0.8em;
}

.numerator, .denominator {
  line-height: 0.9;  /* Tighter line height for fraction parts */
  padding: 0 0.2em;
}

.fraction-line {
  width: 100%;
  border-bottom: 1px solid currentColor;
  margin: 0.1em 0;
}

.inline .numerator,
.inline .denominator {
  font-size: 0.9em;
  line-height: 1.1;
}