.question-container {
    background: rgba(255, 255, 255, 0.1);
    max-width: 500px;
    margin: 1rem auto;
    padding: 0 2px 1rem 2px;
    border-radius: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    width: 80%;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* progress {
    width: 100%;
    margin: 0;
    border-width: 1;
} */

.question {
    transition: color 1s ease-out;
    color: white;
    margin: 0;
    line-height: 2;
    min-height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.question.wrong {
    transition: color 0s;
    color: var(--error); 
}

.question.correct {
    transition: color 0s;
    color: var(--success);
}

form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
}

input {
    padding: 0.5rem;
    font-size: 1.2rem;
    border-radius: 8px;
    border: none;
    background-color: rgba(0, 0, 0, 0.35);
    color: white;
    height: auto;
    max-width: 100%;
}

input:focus {
    outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.game-controls {
    display: flex;
    justify-content: center;
    padding: 2rem;
}

.game-controls button,
.game-over button {
    padding: 1rem 2rem;
    font-size: 1.2rem;
    border: none;
    border-radius: 8px;
    color: rgb(59, 59, 59);
    cursor: pointer;
}

.game-over {
    text-align: center;
    padding: 2rem;
    color: white;
}

.game-over h2 {
    margin-bottom: 1rem;
}

.game-over p {
    margin-bottom: 2rem;
    font-size: 1.2rem;
}