.settings-modal {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    transition: visibility 0s linear 0.25s, opacity 0.25s linear;
    color: #2d2d2d;
}

.settings-modal.open {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
}

.settings-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
}

.settings-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgb(233, 233, 233);
    padding: 0;
    border-radius: 10px;
    width: 350px;
    max-width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: space-between;
}

.settings-close-container {
    display: flex;
    justify-content: flex-end;
}

.settings-content {
    font-size: 0.8em;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 1rem;
}

.setting-group {
    margin: 1rem 0;
}

.setting-group label {
    display: block;
    margin-bottom: 0.5rem;
}

.setting-group select,
.setting-group input {
    width: 80%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 8px;
}