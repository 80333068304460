@import './colors.css';

.main-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    color: white;
}

.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

button {
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 8px;
    box-sizing: border-box;
}

button:hover {
    background: #cae2fc;
}

.question-types {
    margin: 1rem 0;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
}

.type-toggle {
    border: none;
    opacity: 0.3;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    background: white;
    cursor: pointer;
    white-space: nowrap;
    margin: 0.2rem;
    flex: 0 1 auto; /* allows buttons to shrink if needed */
}

.type-toggle.active {
    opacity: 0.8;
}

.score {
    margin: 1rem;
    height: 1em;
}

.toggle-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;  /* This prevents buttons from stretching */
    gap: 0.5rem;
    padding: 1rem;
    width: 100%;
    max-width: 800px;
}

.question-section {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.settings-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.2s, transform 0.5s ease-out;
    padding: 0.5rem;
    margin: 0;
    height: fit-content;
    align-self: center;
    transform: rotate(0deg);
}

.settings-button:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: rotate(120deg);
}

.settings-button img {
    display: block;
    height: 36px;
    width: 36px;
}

