@import 'colors.css';

.footer {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    padding: 1rem 1.5rem;
    box-sizing: border-box;
    font-size: 0.8em;
}

.footer a {
    margin-right: 1rem;
    color: #a6c0f8;
}