body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.App {
  text-align: center;
  background-color: #1d232e;
  height: 100dvh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}
